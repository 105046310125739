import {IStringBoard} from './board-types.ts'

const board: IStringBoard = [
  '',
  '',
  '',
  '',
  '   PACK YOUR',
  '     BAGS!',
  '   THE WORLD',
  '    AWAITS!',
  '',
  '',
  '',
  ''
]

export default board
