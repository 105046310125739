import {IFlightBoard} from './board-types.ts'

const board: IFlightBoard = [
  ['NEW YORK', '09:10'],
  ['ST-BARTH', '09:25'],
  ['IBIZA', '09:45'],
  ['MYKONOS', '10:05'],
  ['MADRID', '10:05'],
  ['LONDON', '10:55'],
  ['PARIS', '11:15'],
  ['ROME', '11:30'],
  ['NAPLES', '11:55'],
  ['MIAMI', '12:10'],
  ['MELBOURNE', '12:25'],
  ['SYDNEY', '12:55']
]

export default board
