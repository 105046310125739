import {IStringBoard} from './board-types.ts'

const board: IStringBoard = [
  '',
  '',
  '',
  '',
  '   ADVENTURE',
  '       IS',
  '   ALWAYS IN',
  '     STYLE',
  '',
  '',
  '',
  ''
]

export default board
