<template>
  <div class="rotating-text">
    <transition mode="out-in" name="fade">
      <span v-html="currentText" :key="`line-${index}`"></span>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import {computed, ComputedRef} from 'vue'

const props = defineProps({
  lines: {
    type: Array<String>,
    default: []
  },
  index: {
    type: Number,
    default: 0
  },
  delay: {
    type: Number,
    default: 6000
  }
})

const currentText: ComputedRef<string> = computed<string>(() => {
  if (props.index >= props.lines?.length) {
    return ''
  }

  return props.lines[props.index]
})
</script>
