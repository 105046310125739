<script setup lang="ts">
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: "You are about to leave the boarding experience"
  },
  message: {
    type: String,
    default: "Are you sure you want to restart ?"
  },
  action: {
    type: Function,
    default: () => {
    }
  }
})

const {title, message, action} = props

const emits = defineEmits(['close'])

const confirmAction = () => {
  action()
  closeDialog()
}

const cancelAction = () => {
  closeDialog()
}

const closeDialog = () => {
  emits('close')
}
</script>
<template>
  <div v-if="showDialog" class="dialog alert-dialog">
    <div class="overlay"></div>
    <div class="dialog-box">
      <h2>{{ title }}</h2>
      <p>{{ message }}</p>
      <div class="actions">
        <button @click="confirmAction">Yes, I do</button>
        <button @click="cancelAction">No, I continue from here</button>
      </div>
    </div>
  </div>
</template>
