<!-- components/Header.vue -->
<template>
  <header class="flex items-center justify-center mb-8" :class="{'pt-25': isHome, 'pt-125':!isHome}" @click.prevent="displayDialog">
    <div class="logo">
      <img src="../assets/images/mk-logo.svg" alt="Michael Kors" class="w-100">
    </div>
  </header>
  <ConfirmDialog
    :showDialog="showDialog"
    :action="executeAction"
    @close="showDialog = false"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import {useRouter} from 'vue-router'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

const showDialog = ref(false)

const router = useRouter()

const displayDialog = () => {
  // console.log('Display dialog')
  if(router.currentRoute.value.path !== '/'){
    showDialog.value = true
  }
}

const isHome = computed(() => {
  return router.currentRoute.value.path === '/'
})

const executeAction = () => {
  // Your action logic here
  console.log('Action executed!')
  navigateHome()
}

const navigateHome = () => {
  router.push('/')
}
</script>



