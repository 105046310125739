// plugins/idleTime.ts

import { App, reactive } from 'vue'
import type { Router } from 'vue-router'

let timer: number
let interval: number

interface Options {
  timeout: number
  pathName: string
  customFunction?: (remainingTime: number) => void
}

export type RemainingTime = {
  remainingTime: number
}

export default {
  install(app: App, router: Router, options: Options) {
    let startTime: number

    const state = reactive({
      remainingTime: options.timeout
    })

    app.provide('$remainingTime', state)

    function resetTimer() {
      clearTimeout(timer)
      clearInterval(interval) // Clear any existing interval

      startTime = Date.now()
      timer = window.setTimeout(() => {
        router.push({ path: options.pathName })
      }, options.timeout)

      interval = window.setInterval(() => {
        // console.log('interval')
        const elapsedTime = Date.now() - startTime
        const timeLeft = options.timeout - elapsedTime
        state.remainingTime = timeLeft

        if (options.customFunction) {
          options.customFunction(timeLeft)
        }

        if (timeLeft <= 0) {
          clearInterval(interval)
        }
      }, 1000) // Update every second
    }

    function setupEventListeners() {
      window.addEventListener('mousemove', resetTimer)
      window.addEventListener('mousedown', resetTimer)
      window.addEventListener('keypress', resetTimer)
      window.addEventListener('touchmove', resetTimer)
    }

    function removeEventListeners() {
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('mousedown', resetTimer)
      window.removeEventListener('keypress', resetTimer)
      window.removeEventListener('touchmove', resetTimer)
    }

    resetTimer()
    setupEventListeners()

    app.unmount = () => {
      clearTimeout(timer)
      clearInterval(interval) // Clear the interval on unmount
      removeEventListeners()
    }
  }
}
