<!-- views/Home.vue -->
<template>
  <div class="p-8">
    <AppContainer>
          <div class="">
            <div class="mb-4   text-gray-700 text-container ">
                <span>
                  We're sorry, you didn't win anything today
                </span>
                <span class="block mt-[1.875rem]">
                  You have also been entered into our NYC prize draw <br/>
                  Winner will be drawn on 12th September 2023 <br/>
                  Good Luck!
                </span>
              </div>
          </div>
          <AppBtnPrimary class="mt-8" @click.stop="navigateHome">
            BACK TO DEPARTURES
          </AppBtnPrimary>
          <div class="absolute text-footer bottom-4 w-150">
              <span class="block text-xs">
                Nous sommes désolés, vous n'avez rien gagné aujourd'hui, mais vous avez été inscrit
              </span>
              <span class="block text-xs">
                au tirage au sort de notre voyage Jet Set à New York, le gagnant sera tiré au sort le 12/09/2023.
                <br/> <span @click.stop="navigateHome">Retour à l’écran des départs</span>
              </span>
          </div>
    </AppContainer>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()

const navigateHome = () => {
  router.push('/')
}
</script>
