<!-- views/QRResult.vue -->
<template>
  <div class="p-8">
    <AppContainer>
          <div class="">
              <div class="mb-4   text-gray-700 text-container ">
                <span>
                  Winner! <br>
                  You’ve won a special Michael Kors gift. See associate today to collect
                </span>
                <span class="block mt-[1.875rem]">
                  You have also been entered into our NYC prize draw <br/>
                  Winner will be drawn on 12th September 2023 <br/>
                  Good Luck!
                </span>
              </div>
          </div>
          <AppBtnPrimary class="" @click="navigateHome">
            BACK TO DEPARTURES
          </AppBtnPrimary>
          <div class="absolute text-footer bottom-4 text-center text-[10px] text-gray-500 mt-2 md:text-xs">
              <span class="block">
                Bravo ! Vous avez gagné un cadeau spécial Michael Kors, voyez avec un conseiller de vente aujourd'hui pour le recevoir
                Vous avez également été inscrit au tirage au sort de notre voyage Jet Set à New York - le gagnant sera tiré au sort le 12/09/2023. Retour à l’écran des départs
              </span>
          </div>
    </AppContainer>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const { qrvalue, signature } = defineProps(['qrvalue', 'signature'])
const router = useRouter()

const navigateHome = () => {
  router.push('/')
}

onMounted(() => {
  console.log(qrvalue, signature)
})
</script>
