<template>
  <div class="ticker-row" ref="element">
  </div>
</template>
<script lang="ts" setup>
import { onBeforeUnmount, onMounted, Ref, ref } from 'vue'
import { Board } from 'ticker-board'
import { config } from './boards/config.ts'

const element: Ref<null | HTMLElement> = ref<null | HTMLElement>(null)
const props = defineProps({
  text: {
    type: Array<String>,
    default: ''
  }
})

let delay = 12000
let interval: null | number = null

const emit = defineEmits(['tick'])

onMounted(() => {
  const board = new Board(element.value, {
    theme: 'dark',
    count: 1,
    size: config.width
  })

  if (props.text && props.text.length > 0) {
    let index = 0
    const rotate = () => {
      if (index >= props.text.length) {
        index = 0
      }
      board.updateMessages([props.text[index]])
      emit('tick', index)

      index++
      interval = window.setTimeout(rotate, 12000)
    }

    rotate()
  }
})

onBeforeUnmount(() => {
  if (interval !== null) {
    clearInterval(interval)
    interval = null
  }
})
</script>
