import {IStringBoard} from './board-types.ts'

const board: IStringBoard = [
  '',
  '',
  '',
  '',
  '      PUT',
  '   YOURSELF',
  '  IN AIRPLANE',
  '     MODE!',
  '',
  '',
  '',
  ''
]

export default board
