<!-- views/Home.vue -->
<template>
   <AppContainer>
       <div class="flex flex-col w-120">
          <span class="text-xl mb-4 font-[600]  text-gray-700 md:text-xl">
            Check in to receive your boarding pass and be in with the chance of winning a prize, including a Jet Set trip to NYC
          </span>
        </div>
        <AppBtnPrimary @click.stop="navigateToForm">
          CHECK IN
        </AppBtnPrimary>
        <div class="absolute bottom-4 px-4 text-center text-[10px] text-gray-500 mt-2 md:text-xs">
          <span>
            Terms and Conditions apply see associate for more details <br>
            Enregistrez-vous, recevez votre carte d'embarquement et tentez de gagner un prix, dont un voyage en Jet Set à New York
            Enregistrement <br/>
            Les conditions générales d'utilisation s’appliquent, voir un conseiller de vente pour plus de détails
          </span>
        </div>
    </AppContainer>
 </template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const allSentences = [
  'Découvrez le monde avec nous.',
  'Tentez votre chance pour gagner un voyage.',
  'Ou d\'autres surprises'
]

let index = 0
let intervalId: number

const displayedSentence = ref(allSentences[index])
const key = ref(0)

const navigateToForm = () => {
  router.push('/forms')
}

onMounted(() => {
  intervalId = window.setInterval(() => {
    index = (index + 1) % allSentences.length
    displayedSentence.value = allSentences[index]
    key.value++ // increment the key to re-trigger the animation
  }, 3000)
})

onUnmounted(() => {
  clearInterval(intervalId);
})
</script>
