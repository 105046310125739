import {config} from './config.ts'
import {boards} from './index.ts'
import {IFlightBoard, IStringBoard} from './board-types.ts'

export class BoardController {
  private _boards: Array<string[]> = []

  get boards(): Array<string[]> {
    return this._boards
  }

  get total(): number {
    return this._boards.length
  }

  /**
   * Convert boards to array of string arrays.
   * @private
   */
  private initBoards(): void {
    boards.forEach((board: IStringBoard | IFlightBoard) => {
      board.forEach((items: string | string[], index: number) => {
        let col: string = ''
        if (typeof items === 'string') {
          col = `${items}${' '.repeat(config.width)}`
        } else {
          col = `${items[0]}${' '. repeat(config.width - items[0].length - items[1].length)}${items[1]}`
        }

        if (index >= this.total) {
          this._boards.push([])
        }

        this._boards[index].push(col)
      })
    })
  }

  /**
   * Initialize a new instance of the BoardController class.
   */
  constructor() {
    this.initBoards()
  }
}
