<!-- views/Home.vue -->
<template>
  <div class="p-8">
    <AppContainer >
      <div class="text-xl mb-4 font-medium w-80 text-gray-700 md:text-xl" >
          <span>Check your inbox for your boarding pass</span>
      </div>
      <div class="bottom-3 absolute  border-0 text-center text-[10px] text-gray-500 mt-2 md:text-xs">
          <span>
            Vérifiez votre boîte de réception pour votre carte d'embarquement et scannez-la ci-dessous
          </span>
      </div>
    </AppContainer>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const allSentences = [
  'Vous allez prochainement recevoir un mail de confirmation.',
  'Scannez-le pour découvrir votre cadeau.',
  'Bonne chance'
]

let index = 0
let intervalId: number

const displayedSentence = ref(allSentences[index])
const key = ref(0)

const navigateHome = () => {
  router.push('/')
}


onMounted(() => {
  intervalId = window.setInterval(() => {
    index = (index + 1) % allSentences.length
    displayedSentence.value = allSentences[index]
    key.value++ // increment the key to re-trigger the animation
  }, 3000)
})

onUnmounted(() => {
  clearInterval(intervalId);
})
</script>
