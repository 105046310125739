<script lang="ts" setup>
import {onMounted, watch} from 'vue'
import Keyboard from 'simple-keyboard'
import {useKeyboardStore} from '../../store/keyboard.ts'
import {useRoute} from 'vue-router'
import {EnglishKeyboard} from './keyboards/en-US.ts'
import 'simple-keyboard/build/css/index.css'

let keyboard: Keyboard | null = null
const keyboardStore = useKeyboardStore()
const route = useRoute()

const emit = defineEmits(['keypress'])

const props = defineProps({
  keyboardClass: {
    type: String,
    default: 'simple-keyboard'
  }
})

const onChange = (inputString: string) => {
  if (keyboardStore.input !== null) {
    (keyboardStore.input as HTMLInputElement).value = inputString
    keyboardStore.input.dispatchEvent(new Event('input'))
    keyboardStore.setTyping(true)
  }
}

const handleShiftPress = () => {
  if (keyboard !== null) {
    keyboard.setOptions({layoutName: keyboard.options.layoutName === 'default' ? 'shift' : 'default'})
  }
}

const onKeyPress = (button: string) => {
  emit('keypress', button)

  if (button === '{shift}' || button === '{lock}') {
    handleShiftPress()
  }
}

watch(() => route.path, () => {
  keyboardStore.setTyping(false)
  keyboardStore.setVisible(false)
})

watch(() => keyboardStore.input, (value: null | HTMLInputElement) => {
  if (value !== null && keyboard !== null) {
    keyboard.setInput(value.value)
  }
})

onMounted(() => {
  keyboard = new Keyboard(props.keyboardClass, {onChange, onKeyPress})
  keyboard.setOptions({layout: EnglishKeyboard})
})
</script>
<template>
  <div :class="keyboardClass" v-show="keyboardStore.isVisible"/>
</template>
