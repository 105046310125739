<!-- components/DebugClickListener.vue -->
<template>
  <div class="debug-click-listener"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

// Define the emit types
const emit = defineEmits(['debug'])

const topLeftClicks = ref(0)
const topRightClicks = ref(0)
const requiredClicksLeft = 2
const requiredClicksRight = 3
const debugMode = ref(false)

const handleDocumentClick = (event: MouseEvent) => {
  const width = window.innerWidth
  const height = window.innerHeight

  // Check if click is in the top left corner
  if (event.clientX < width * 0.1 && event.clientY < height * 0.1) {
    topLeftClicks.value++
  }

  // Check if click is in the top right corner
  if (event.clientX > width * 0.9 && event.clientY < height * 0.1) {
    topRightClicks.value++
  }

  // Check if the conditions are met
  if (topLeftClicks.value === requiredClicksLeft && topRightClicks.value === requiredClicksRight) {
    debugMode.value = !debugMode.value // Toggle the debug mode
    emit('debug', debugMode.value)
    // Reset the click counts
    topLeftClicks.value = 0
    topRightClicks.value = 0
  }
}

// Reset mechanism to clear the click counts after a certain time
let resetTimeout: number
onMounted(() => {
  document.addEventListener('click', handleDocumentClick)
  document.addEventListener('click', () => {
    clearTimeout(resetTimeout)
    resetTimeout = window.setTimeout(() => {
      topLeftClicks.value = 0
      topRightClicks.value = 0
    }, 5000) // Reset after 5 seconds of inactivity
  })
})

onUnmounted(() => {
  document.removeEventListener('click', handleDocumentClick)
  clearTimeout(resetTimeout)
})
</script>

<style>
.debug-click-listener {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; /* Ensure it's on top of other elements */
  pointer-events: none; /* This ensures the layer doesn't capture any mouse events */
}
</style>
