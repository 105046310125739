import {App} from 'vue'
import {useClickOutside} from '../../../lib/use-click-outside'
import {useKeyboardStore} from '../../../store/keyboard.ts'

const useOnScreenKeyboard = (app: App) => {
  const keyboardStore = useKeyboardStore()

  const {bind} = useClickOutside(['.simple-keyboard', '.on-screen-keyboard-element'], () => {
    keyboardStore.setVisible(false)
    keyboardStore.setTyping(false)
  })

  bind()

  app.directive('on-screen-keyboard', {
    mounted(el) {
      const onFocus = () => {
        keyboardStore.setInput(el)
        keyboardStore.setVisible(true)
        keyboardStore.setTyping(true)
      }

      const onBlur = () => {
        if (!keyboardStore.isTyping) {
          keyboardStore.setVisible(false)
        } else {
          setTimeout(() => {
            keyboardStore.focus()
          }, 100)
        }
      }

      el.addEventListener('focus', onFocus)
      el.addEventListener('blur', onBlur)

      el.classList.add('on-screen-keyboard-element')
    }
  })
}

export {useOnScreenKeyboard}
