import {createApp} from 'vue'
import {createPinia} from 'pinia'

import 'virtual:uno.css'
import '@unocss/reset/eric-meyer.css'
import 'animate.css'
import './assets/styles/index.scss'

import {useOnScreenKeyboard} from './components/OnScreenKeyboard/directives/on-screen-keyboard.ts'
import App from './App.vue'
import Header from './components/AppHeader.vue'
import Footer from './components/AppFooter.vue'
import AppContainer from './components/AppContainer.vue'
import BtnPrimary from './components/AppBtnPrimary.vue'
import {router} from './router'
import idleTimePlugin from './plugins/idleTime'

import { version } from '../../../package.json'

const app = createApp(App)

app.config.globalProperties.$appVersion = version


app.component('AppHeader', Header)
app.component('AppContainer', AppContainer)
app.component('AppBtnPrimary', BtnPrimary)
app.component('AppFooter', Footer)

app.use(createPinia())
app.use(router)
app.use(idleTimePlugin, router, {
  timeout: 65000,
  pathName: '/'
})

useOnScreenKeyboard(app)
app.mount('#app')
