import claimOne from './claim-one.ts'
import claimTwo from './claim-two.ts'
import claimThree from './claim-three.ts'
import flightBoard1 from './flight-board-1.ts'
import flightBoard2 from './flight-board-2.ts'
import {IFlightBoard, IStringBoard} from './board-types.ts'

export const boards: Array<IStringBoard | IFlightBoard> = [
  flightBoard1,
  claimOne,
  flightBoard2,
  claimTwo,
  flightBoard2,
  claimThree
]
