<script setup lang="ts">
import {ref, watchEffect, inject} from 'vue'
import {useRouter} from 'vue-router'
import type {RemainingTime} from '../plugins/idleTime.ts'

const router = useRouter()
const showDialog = ref(false)
const remainingTime = inject<RemainingTime>('$remainingTime')
const idleTime = 5000

watchEffect(() => {
  const newTime = remainingTime?.remainingTime ?? 0
  showDialog.value = router.currentRoute.value.path !== '/' && newTime <= idleTime && newTime >= 0
})

</script>
<template>
  <div v-if="showDialog" class="dialog idle-dialog">
    <div class="overlay"></div>
    <div class="dialog-box">
      <h2>Information</h2>
      <p>You will be redirected due to inactivity.</p>
      <p>Time left: <strong>{{ Math.ceil((remainingTime as RemainingTime).remainingTime / 1000) }}</strong> seconds</p>
    </div>
  </div>
</template>
