<!-- components/AlertDialog.vue -->
<template>
  <div v-if="showDialog" class="dialog idle-dialog">
    <div class="overlay" @click="closeDialog"></div>
    <div class="dialog-box">
      <h2>Information</h2>
      <p class="w-100 p-4 mb-4">{{message}}</p>
      <button class="m-auto min-w-20 bg-[#25344a] flex items-center justify-center px-12 py-2 transition hover:bg-gray-800 cursor-pointer text-xs md:text-base font-semibold text-white" @click="closeDialog">OK</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const emit = defineEmits()

const props = defineProps({
  showDialog: Boolean,
  message: String
})

const closeDialog = () => {
  emit('close')
}
</script>