<template>
  <div class="network-quality">
    <div v-if="isOffline" class="offline-status">Offline</div>
    <div v-else>
      <div v-if="connectionType">Connection Type: {{ connectionType }}</div>
      <div v-if="effectiveType">Effective Type: {{ effectiveType }}</div>
      <div v-if="downlink">Downlink: {{ downlink }} Mbps</div>
      <div v-if="rtt">Round Trip Time: {{ rtt }} ms</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

const isOffline = ref(!navigator.onLine)
const connectionType = ref<string | null>(null)
const effectiveType = ref<string | null>(null)
const downlink = ref<number | null>(null)
const rtt = ref<number | null>(null)

const updateNetworkInfo = () => {
  if (navigator.connection) {
    connectionType.value = navigator.connection.type || null
    effectiveType.value = navigator.connection.effectiveType || null
    downlink.value = navigator.connection.downlink || null
    rtt.value = navigator.connection.rtt || null
    console.log(navigator.connection.rtt)
  }
}

const handleOnlineStatus = () => {
  isOffline.value = !navigator.onLine
}

onMounted(() => {
  updateNetworkInfo()
  // Listen for changes in connection info
  if (navigator.connection) {
    navigator.connection.addEventListener('change', updateNetworkInfo)
  }
  // Listen for online and offline events
  window.addEventListener('online', handleOnlineStatus)
  window.addEventListener('offline', handleOnlineStatus)
})

onUnmounted(() => {
  if (navigator.connection) {
    navigator.connection.removeEventListener('change', updateNetworkInfo)
  }
  window.removeEventListener('online', handleOnlineStatus)
  window.removeEventListener('offline', handleOnlineStatus)
})
</script>

<style>
.network-quality {
  /* Add any desired styling here */
  font-size: 2.2rem;
  font-family: 'Kors';
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 470px;
  height: auto;
}

.offline-status {
  color: red;
  font-weight: bold;
}
</style>
