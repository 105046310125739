import {defineStore} from 'pinia'

export type KeyboardState = {
  isVisible: boolean,
  input: null | HTMLInputElement,
  isTyping: boolean
}

export const useKeyboardStore = defineStore({
  id: 'keyboardStore',
  state: () => ({
    isVisible: false,
    input: null,
    isTyping: false
  } as KeyboardState),

  actions: {
    setVisible(isVisible: boolean) {
      this.isVisible = isVisible
    },

    setTyping(isTyping: boolean) {
      this.isTyping = isTyping
    },

    setInput(input: null | HTMLInputElement) {
      this.input = input
    },

    focus() {
      if (this.isVisible && this.input !== null) {
        this.input.focus()
      }
    }
  }
})
