<template>
  <div class="app-version">
    version: {{ appVersion }}
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance } from 'vue'

// Access the global property
const appVersion = ref((getCurrentInstance()?.appContext.config.globalProperties.$appVersion) || 'Unknown')
</script>

<style>
.app-version {
  width: auto;
  height: 39px;
  font-size: 2rem;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: navajowhite;
  font-weight: light;
  font-family: 'Kors', monospace;
}
</style>
