import {IFlightBoard} from './board-types.ts'

const board: IFlightBoard = [
  ['CAPE TOWN', '14:05'],
  ['MADRID', '14:30'],
  ['ZANZIBAR', '15:10'],
  ['MARRAKESH', '15:25'],
  ['PARIS', '15:50'],
  ['NEW YORK', '16:20'],
  ['HONG KONG', '16:40'],
  ['SYDNEY', '17:20'],
  ['LONDON', '17:40'],
  ['NAPLES', '17:55'],
  ['MYKONOS', '18:30'],
  ['IBIZA', '18:50']
]

export default board

