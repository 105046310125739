<template>
  <div class="text-center h-full items-center justify-center flex font-poppins bg-image">
    <div>
      <AppHeader/>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
      <qrcode-stream class="offset" :class="{debug: isDebug}" @detect="onDetect"></qrcode-stream>
      <IdleDialog/>
    </div>
  </div>
  <teleport to="body">
    <on-screen-keyboard/>
  </teleport>
  <DebugClickListener @debug="handleDebugEvent"/>
  <AppVersion class="hidden" :class="{debug: isDebug}"/>
  <NetworkQuality class="hidden" :class="{debug: isDebug}"/>
</template>


<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { QrcodeStream } from 'vue-qrcode-reader'
import AppVersion from './components/AppVersion.vue'
import NetworkQuality from './components/NetworkQuality.vue'
import IdleDialog from './components/AppIdleDialog.vue'
import DebugClickListener from './components/DebugClickListener.vue'
import OnScreenKeyboard from './components/OnScreenKeyboard/OnScreenKeyboard.vue'

const router = useRouter()

const onDetect = async (content: string) => {
  console.log(content)
  try {
    const gift = JSON.parse(content[0]?.rawValue)
    if(gift.chosenLot !== 'none') {
      console.log('win')
      router.push('/qr-result')
    } else  {
      console.log('loose')
      router.push('/loose')
    }
  } catch (error) {
    console.log(error)
  }
  console.log('Decrypted:', content)
}

const isDebug = ref(false)

const handleDebugEvent = (value: boolean) => {
  isDebug.value = value
}

const onInit = async (promise: Promise<any>) => {
  try {
    await promise
  } catch (error) {
    if ((error as any).name === 'NotAllowedError') {
      console.log('ERROR: you need to grant camera permissions to use QR Scanner')
    } else if ((error as any).name === 'NotFoundError') {
      console.log("ERROR: no camera on this device")
    } else if ((error as any).name === 'NotSupportedError') {
      console.log("ERROR: secure context required (HTTPS, localhost)")
    } else if ((error as any).name === 'NotReadableError') {
      console.log("ERROR: is the camera already in use?")
    } else if ((error as any).name === 'OverconstrainedError') {
      console.log("ERROR: installed cameras are not suitable")
    } else if ((error as any).name === 'StreamApiNotSupportedError') {
      console.log("ERROR: Stream API is not supported in this browser")
    }
  }
}
</script>
