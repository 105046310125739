<!-- views/Home.vue -->
<template>
  <div class="home-wrapper" @click.prevent="navigateToExplanation">
    <ticker-board @tick="updateRotatingTextIndex" />
    <div class="p-10">
      <div>
        <button class="text-3xl py-1 px-10">TAP TO PLAY</button>
      </div>
      <div class="text-white mt-5 text-center text-xl px-5">
        <rotating-text :lines="rotatingText" :index="rotatingTextIndex" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RotatingText from '../components/Home/RotatingText.vue'
import TickerBoard from '../components/Home/TickerBoard.vue'
import { useRouter } from 'vue-router'
import { ref, Ref, onMounted } from 'vue'

const rotatingTextIndex: Ref<number> = ref<number>(0)
const router = useRouter()

const navigateToExplanation = () => {
  router.push('/explanation')
}

const updateRotatingTextIndex = (index: number) => {
  rotatingTextIndex.value = index
}

const rotatingText: Ref<string[]> = ref<string[]>([
  'Cliquez pour jouer',
  'Cliquez pour jouer<br>Faites vos valises ! Le monde vous attend !',
  'Cliquez pour jouer',
  'Cliquez pour jouer<br>Mettez vous en mode avion !',
  'Cliquez pour jouer',
  'Cliquez pour jouer<br>L\'aventure est toujours à la mode',
  'Cliquez pour jouer'
])

onMounted(()=>{
  localStorage.removeItem('formData')
})
</script>
