<!-- views/Forms.vue -->
<template>
  <div class="h-full">
    <AppContainer>
      <h3 class="text-xl mb-4 font-medium  text-gray-700 md:text-xl w-100 pt-10">
        Check in below to be in <br> with the chance of winning a prize, including a Jet Set trip to NYC
      </h3>

      <form @submit.prevent="submitForm" class="mt-1  space-y-2 px-8 md:px-20 -ml-6">
        <div class="flex flex-col items-center">
          <div class="w-full px-4  relative">
            <input
              v-model="name"
              placeholder="Firstname / Prénom"
              type="text"
              class="w-full py-2 px-4 text-white placeholder-white text-lg outline-none bg-[#25344a]"
              v-on-screen-keyboard
            />
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div class="w-full relative px-4">
            <input
              v-model="fname"
              placeholder="Surname / Nom"
              type="text"
              class="w-full py-2 px-4 text-white placeholder-white text-lg outline-none bg-[#25344a]"
              v-on-screen-keyboard
            />
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div class=" w-full relative px-4">
            <input
              v-model="email"
              placeholder="Email"
              type="email"
              class="w-full py-2 px-4 text-white placeholder-white text-lg outline-none bg-[#25344a]"
              v-on-screen-keyboard
            />
            <div v-if="showTooltip" class="w-full relative px-4 mt-2 cursor-pointer" @click="applySuggestedEmail">
              <div class="p-2 rounded bg-gray-200 text-gray-700 flex justify-between items-center">
                Did you mean: <span class="font-bold">{{ suggestedEmail }}</span>?
                <span class="text-xl ml-4 cursor-pointer" @click.stop="closeTooltip">×</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col ">
          <div class="w-full relative">
            <label class="flex items-start justify-start text-left gap-1">
              <input type="checkbox" v-model="acceptCondition" />
              <span class="text-gray-400 text-[13.511px] md:text-base">
                  I agree to the full terms and conditions apply, see associate for details or click here
                <span class="underline text-gray-400" @click.prevent="navigateToConditions">click here</span>
              </span>
            </label>
          </div>
        </div>
        <AppBtnPrimary @click.prevent="submitForm">
          Get Boarding Pass
        </AppBtnPrimary>
      </form>
      <div class="text-center text-[8px] text-gray-500 mt-2 w-100">
          <span>
            Enregistrez-vous ci-dessous pour avoir une chance de gagner un prix, y compris un voyage Jet Set à New York
            J’accepte les conditions générales d'utilisation, voir un conseiller de vente pour plus de détails ou cliquez ici Obtenir ma carte d’embarquement
          </span>
      </div>
    </AppContainer>
    <AlertDialog :showDialog="showDialog" :message="message" @close="handleCloseDialog"/>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { supabase } from '../supabase'
import AlertDialog from '../components/AlertDialog.vue'

const name = ref('')
const fname = ref('')
const email = ref('')
const acceptCondition = ref(false)
const message = ref('')
const showDialog = ref(false)
const showTooltip = ref(false);
const suggestedEmail = ref('');

const router = useRouter()

const showAlert = (msg: string) => {
  message.value = msg
  showDialog.value = true
}

const handleCloseDialog = () => {
  showDialog.value = false
}

const navigateThanks = () => {
  clearFormData()
  router.push('/thanks-forms')
}

const navigateToConditions = () => {
  router.push('/conditions')
}

const applySuggestedEmail = () => {
  email.value = suggestedEmail.value;
  closeTooltip();
}

const closeTooltip = () => {
  showTooltip.value = false;
}

// Fonction pour vérifier la validité d'une adresse e-mail
const isValidEmail = (email: string): boolean => {
  const regex = /^[a-zA-Z0-9._-]+@(?!\.)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

const suggestCorrectedEmail = (email: string): string | null => {
  let [localPart, domainPart] = email.split('@');

  // Clean the local part
  localPart = localPart.replace(/[^a-zA-Z0-9._-]/g, '');

  // Clean the domain part
  const domainSegments = domainPart.split('.');
  const cleanedSegments = domainSegments.map(segment => {
    return segment.replace(/[^a-zA-Z0-9-]/g, '').replace(/^-+|-+$/g, '');
  });

  // Reassemble the domain part without empty segments or consecutive dots
  domainPart = cleanedSegments.filter(segment => segment).join('.');

  // Reassemble the email
  const suggestedEmail = `${localPart}@${domainPart}`;

  return suggestedEmail !== email ? suggestedEmail : null;
}

// Fonction pour valider les champs du formulaire
const validateForm = (): boolean => {
  if (!isValidEmail(email.value)) {
    const suggested = suggestCorrectedEmail(email.value);
    if (suggested) {
      showSuggestedEmailTooltip(suggested);
    } else {
      showAlert('Veuillez entrer une adresse e-mail valide.');
    }
    return false;
  }

  if (name.value.length === 0 || fname.value.length === 0) {
    showAlert('Le nom et le prénom ne doivent pas être vides.');
    return false;
  }

  if (!acceptCondition.value) {
    showAlert('Veuillez accepter les conditions.');
    return false;
  }

  return true;
}

const saveFormDataToLocalStorage = () => {
  const formData = {
    name: name.value,
    fname: fname.value,
    email: email.value,
    acceptCondition: acceptCondition.value
  }
  localStorage.setItem('formData', JSON.stringify(formData))
}

// Method to load form data from local storage
const loadFormDataFromLocalStorage = () => {
  const formData = JSON.parse(localStorage.getItem('formData') || '{}')
  if (formData) {
    name.value = formData.name || ''
    fname.value = formData.fname || ''
    email.value = formData.email || ''
    acceptCondition.value = formData.acceptCondition || false
  }
}

const clearFormData = () => {
  // Reset form data refs
  name.value = ''
  fname.value = ''
  email.value = ''
  acceptCondition.value = false

  // Remove form data from local storage
  localStorage.removeItem('formData')
}

const showSuggestedEmailTooltip = (suggestion: string) => {
  suggestedEmail.value = suggestion;
  showTooltip.value = true;
}

const submitForm = async () => {
  console.log('-> submitForm')
  // Vérifiez d'abord la validité des champs
  if (!validateForm()) {
    return;
  }

  const { error } = await supabase
    .from('subscriber')
    .insert([
      { name: name.value, fname: fname.value, email: email.value, accept_condition: acceptCondition.value},
    ])
    .select()

  if (error) {
    console.error('Error submitting form:', error)
    showAlert('Il y a eu une erreur lors de la soumission du formulaire.')
  } else {
    navigateThanks()
  }
}

watch(name, saveFormDataToLocalStorage)
watch(fname, saveFormDataToLocalStorage)
watch(email, () => {
  showTooltip.value = false
  saveFormDataToLocalStorage()
})
watch(acceptCondition, saveFormDataToLocalStorage)

onMounted(() => {
  loadFormDataFromLocalStorage()
})

onUnmounted(() => {
})
</script>
