// router.ts
import {createRouter, createWebHistory} from 'vue-router'
import Home from './views/Home.vue'
import QRCode from './views/QRCode.vue'
import Explanation from './views/Explanation.vue'
import Forms from './views/Forms.vue'
import ThanksForms from './views/ThanksForms.vue'
import QRResult from './views/QRResult.vue'
import QRResultLoose from './views/QRResultLoose.vue'
import Conditions from './views/Conditions.vue'

const routes = [
  {path: '/', component: Home},
  {path: '/qrcode', component: QRCode},
  {path: '/explanation', component: Explanation},
  {path: '/forms', component: Forms},
  {path: '/conditions', component: Conditions},
  {path: '/thanks-forms', component: ThanksForms},
  {path: '/loose', component: QRResultLoose},
  {
    path: '/qr-result/:qrvalue?/:signature?',
    component: QRResult,
    props: true  // Cela passera les paramètres d'URL en tant que props au composant
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})
