interface IUseClickOutside {
  bind(): void
  unbind(): void
}

type UseClickOutsideCallback = () => void

/**
 * Compare two DOM nodes.
 * @param a
 * @param b
 */
const compareNodes = (a: Node, b: Node) => !a.isSameNode ? a === b : a.isSameNode(b)

/**
 * Check whether node is equal or is a children of the given possible parent.
 * @param node
 * @param possibleParent
 */
const hasParentLike = (node: Node | null, possibleParent: Node): boolean => {
  for (; node && node !== document; node = node.parentNode) {
    if (compareNodes(node, possibleParent)) {
      return true
    }
  }
  return false
}

/**
 * Click outside factory.
 * @param ignoreSelector
 * @param callback
 */
const useClickOutside = (ignoreSelector: string[] = [], callback: null | UseClickOutsideCallback = null): IUseClickOutside => {
  const makeCall = () => {
    if (callback) {
      callback()
    }
  }

  const clickedOutside = (ev: MouseEvent) => {
    if (!ignoreSelector.length) {
      makeCall()
      return
    }

    let ignore = false
    for (const selector of ignoreSelector) {
      const ignoreElements = document.querySelectorAll(selector)
      if (ignoreElements && ignoreElements.length > 0) {
        ignoreElements.forEach(ignoreItem => {
          if (ignoreItem && hasParentLike(ev.target as Node, ignoreItem)) {
            ignore = true
          }
        })
      }
    }
    if (!ignore) {
      makeCall()
    }
  }

  return {
    bind() {
      window.addEventListener('click', clickedOutside)
    },
    unbind() {
      window.removeEventListener('click', clickedOutside)
    }
  }
}

export {useClickOutside}
