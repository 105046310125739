<template>
  <div class="ticker-board">
    <div>
      <div
          v-for="i in boardSize"
          :key="`board-row-${i}`"
      >
        <ticker-row :text="controller.boards[i - 1]" @tick="(index) => emitTick(i - 1, index)" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {Ref, ref} from 'vue'
import TickerRow from './TickerRow.vue'
import {config} from './boards/config.ts'
import {BoardController} from './boards/board-controller.ts'

const boardSize: Ref<number> = ref<number>(config.height)
const controller = new BoardController()

const emit = defineEmits(['tick'])
const emitTick = (rowIndex: number, index: number) => {
  if (!rowIndex) {
    emit('tick', index)
  }
}
</script>
